/* eslint-disable react/forbid-prop-types */
import {
  useAccount,
} from 'account';
import {
  AccountPermissions,
} from 'account/view_model/account.viewmodel';
import navBarController from 'nav_bar/controller/nav_bar.controller';
import {
  AccountButtonSx,
  AccountImageSx,
  DropDownIconSx,
  ContainerSx,
  DrawerSx,
  DrawerAboutIconSx,
  DrawerContactIconSx,
  DrawerDividerSx,
  DrawerDocumentsIconSx,
  DrawerResourcesIconSx,
  DrawerHomeIconSx,
  DrawerLoginIconSx,
  DrawerLogoutIconSx,
  DrawerNavItemSx,
  DrawerNavItemListSx,
  DrawerNavLinkSx,
  DrawerNavLinkTextSx,
  DrawerServiceIconSx,
  InlineNavItemListSx,
  InlineNavLinkSx,
  InlineNavLinkTextSx,
  InlineNavMenuSx,
  InnerSx,
  LoginButtonSx,
  LogoNavLinkSx,
  LogoNavLinkImageSx,
  LogoNavLinkTextSx,
  MoreMenuIconSx,
  MoreMenuButtonSx,
  PopoverSx,
  PopoverContainerSx,
  PopoverListSx,
  PopoverLogoutButtonSx,
  DrawerLogoutButtonSx,
  PopoverProfileButtonSx,
  DrawerProfileButtonSx,
  DrawerProfileIconSx,
  PopoverAdminButtonSx,
  DrawerAdminIconSx,
  DrawerAdminButtonSx,
  DrawerServiceApprovalIconSx,
  DrawerProfessionalExperienceApprovalIconSx,
  PopoverServiceApprovalButtonSx,
  DrawerReunionPageScheduleDetailsIconSx,
  DrawerReunionPageSpeakerIconSx,
  DrawerReunionPageFAQIconSx,
  DrawerReunionPageGuestsIconSx
} from 'nav_bar/styles/nav_bar.style';
import {
  Badge,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, {
  ReactElement,
  useState,
  useEffect,
} from 'react';
import * as PATHS from 'routing/paths';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import apiAdapter from 'api_adapter';
import styled from '@emotion/styled';

/**
 * Retrieved the icon for the navigation item based on the item's id.
 *
 * @param {String} id the id of the navigation item.
 *
 * @returns {ReactElement} the icon component.
 */
function _getNavigationIcon(id) {
  switch (id) {
    case 'home':
      return <DrawerHomeIconSx />;
    case 'about':
      return <DrawerAboutIconSx />;
    case 'service':
      return <DrawerServiceIconSx />;
    case 'documents':
      return <DrawerDocumentsIconSx />;
    case 'resources':
      return <DrawerResourcesIconSx />;
    case 'reunion':
      return <DrawerReunionIconSx />;
    case 'contact':
      return <DrawerContactIconSx />;
    case 'login':
      return <DrawerLoginIconSx />;
    case 'logout':
      return <DrawerLogoutIconSx />;
    case 'profile':
      return <DrawerProfileIconSx />;
    case 'admin':
      return <DrawerAdminIconSx />;
    case 'serviceapproval':
      return <DrawerServiceApprovalIconSx />;
    case 'profexpapproval':
      return <DrawerProfessionalExperienceApprovalIconSx />
    case 'schedule':
      return <DrawerReunionPageScheduleDetailsIconSx />
    case 'speakers':
      return <DrawerReunionPageSpeakerIconSx />
    case 'guests':
      return <DrawerReunionPageGuestsIconSx />
    case 'faq':
      return <DrawerReunionPageFAQIconSx />
    default:
      return <DrawerHomeIconSx />;
  }
}

/**
 * Displays the navigation bar used to navigate to other pages. It also contains
 * the user account actions (login, logout, user profile, etc.).
 *
 * @param {Object} props
 * @param {String} props.active the active page.
 *
 * @returns {ReactElement} the admin bar component.
 */
export default function NavBar({ active, reunion = false }) {
  const theme = useTheme();

  // drawer states and actions
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  // popover states and actions
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const onPopoverClickRequest = (event) => {
    // set the filter icon as the anchor instead of the button
    setPopoverAnchorEl(event.currentTarget);
  };
  const onPopoverCloseRequest = () => {
    setPopoverAnchorEl(null);
  };

  // account states and actions
  const { account, refreshAccount } = useAccount();
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [permissionVal, setPermissionVal] = useState(0);

  // states for notifications
  const [overallNotif, setOverallNotif] = useState(false);
  const [numNewService, setNumNewService] = useState(0);
  const [numNewProfExp, setNumNewProfExp] = useState(0);
  const [usingDefaultPW, setUsingDefaultPW] = useState(false);

  const navigate = useNavigate();
  const onLogout = () => {
    navBarController.logout().then(() => refreshAccount());
  };

  useEffect(() => {
    const isLoggedInPrev = isLoggedIn;
    if (!account) {
      setIsLoggedIn(null);
      setPermissionVal(null);
    } else if (account.permissions === AccountPermissions.UNAUTH) {
      if (isLoggedInPrev) {
        navigate(PATHS.HOME);
      }
      setIsLoggedIn(false);
      setPermissionVal(AccountPermissions.INVALID);
    } else {
      setIsLoggedIn(true);
      setPermissionVal(account.permissions);

      const fetchData = async () => {
        // set components for notifications
        const rspDftPw = await apiAdapter.defaultUserCheck()
        setUsingDefaultPW(rspDftPw == 1 ? true : false);
        if (rspDftPw == 1) {
          setOverallNotif(true);
        }
        if (account.permissions === AccountPermissions.ACTIVITIES_CHAIR || account.permissions === AccountPermissions.ACTIVITIES_REP || account.permissions === AccountPermissions.WEBMASTER) {
          const countProfExp = await apiAdapter.countBlankProfExp()
          setNumNewProfExp(countProfExp);
          if (countProfExp > 0) setOverallNotif(true);
        }
        if (account.permissions === AccountPermissions.SERVICE_CHAIR || account.permissions === AccountPermissions.CLASS_SERVICE_REP || account.permissions === AccountPermissions.WEBMASTER) {
          const countService = await apiAdapter.countBlankService();
          setNumNewService(countService);
          if (countService > 0) setOverallNotif(true);
        }
      }

      fetchData();

    }
  }, [account]);

  return (
    <ContainerSx color="navbar" position="sticky">
      <InnerSx>
        <InlineNavMenuSx>
          <>
            <MoreMenuButtonSx onClick={toggleDrawer(true)}>
              <MoreMenuIconSx />
            </MoreMenuButtonSx>
            <NavBar.NavDrawer
              active={active}
              isLoggedIn={isLoggedIn}
              isOpen={isDrawerOpen}
              onClose={toggleDrawer(false)}
              onLogout={onLogout}
              reunion={reunion}
            />
          </>
          <LogoNavLinkSx to={PATHS.HOME}>
            <LogoNavLinkImageSx src="/images/clarkson_logo.png" />
            <LogoNavLinkTextSx>Clarkson Honors Program</LogoNavLinkTextSx>
          </LogoNavLinkSx>
          <InlineNavItemListSx>
            {navBarController.readNavBarItems(reunion).map((item) => (
              <InlineNavLinkSx
                theme={theme}
                to={item.path}
                key={`nav-item-${item.id}`}
              >
                <InlineNavLinkTextSx linkid={item.id} activeid={active}>
                  {item.label}
                </InlineNavLinkTextSx>
              </InlineNavLinkSx>
            ))}
          </InlineNavItemListSx>
          <InlineNavItemListSx>
            {!reunion && isLoggedIn != null && !isLoggedIn &&
              <LoginButtonSx to={PATHS.LOGIN}>LOG IN</LoginButtonSx>}
            {!reunion && isLoggedIn != null && isLoggedIn && (
              <Badge color='error' badgeContent={overallNotif ? "!" : 0}>
                <AccountButtonSx onClick={onPopoverClickRequest}>
                  <AccountImageSx src="/images/members/blank_profile.png" />
                  <DropDownIconSx />
                </AccountButtonSx>
              </Badge>
            )}
            <NavBar.Popover
              isOpen={Boolean(popoverAnchorEl)}
              anchorEl={popoverAnchorEl}
              onCloseRequest={onPopoverCloseRequest}
              onLogout={onLogout}
              permissions={permissionVal}
              usingDefaultPassword={usingDefaultPW}
              countNewService={numNewService}
              countNewExp={numNewProfExp}
            />
          </InlineNavItemListSx>
        </InlineNavMenuSx>
      </InnerSx>
    </ContainerSx>
  );
}

NavBar.propTypes = {
  active: PropTypes.string.isRequired,
};

/**
 * Displays the navigation drawer for smaller screens.
 *
 * @param {Object} props
 *
 * @param {String} props.active the active page.
 * @param {boolean} props.isOpen whether the drawer is open.
 * @param {Function} props.onClose action to be performed when drawer is closed.
 *
 * @returns {ReactElement} the admin bar component.
 */
NavBar.NavDrawer = function NavBarNavDrawer({
  active,
  isLoggedIn,
  isOpen,
  onClose,
  onLogout,
  reunion,
}) {
  const theme = useTheme();

  return (
    <DrawerSx open={isOpen} onClose={onClose}>
      <DrawerNavItemListSx>
        {navBarController.readNavBarItems(reunion).map((item) => (
          <DrawerNavItemSx key={`drawer-nav-item-${item.id}`}>
            <DrawerNavLinkSx
              theme={theme}
              to={item.path}
              key={`nav-item-${item.id}`}
              linkid={item.id}
              activeid={active}
            >
              {_getNavigationIcon(item.id)}
              <DrawerNavLinkTextSx>
                {item.label}
              </DrawerNavLinkTextSx>
            </DrawerNavLinkSx>
          </DrawerNavItemSx>
        ))}
        <DrawerDividerSx />
        {isLoggedIn !== null && !isLoggedIn && !reunion && (
          <DrawerNavItemSx key="drawer-nav-item-login">
            <DrawerNavLinkSx
              theme={theme}
              to={PATHS.LOGIN}
              key="nav-item-login"
              linkid="login"
              activeid={active}
            >
              {_getNavigationIcon('login')}
              <DrawerNavLinkTextSx>
                Log In
              </DrawerNavLinkTextSx>
            </DrawerNavLinkSx>
          </DrawerNavItemSx>
        )}
        {isLoggedIn !== null && isLoggedIn && (
          <DrawerNavItemSx key="drawer-nav-item-login">
            <DrawerLogoutButtonSx onClick={onLogout}>
              {_getNavigationIcon('logout')}
              Log out
            </DrawerLogoutButtonSx>
          </DrawerNavItemSx>
        )}
      </DrawerNavItemListSx>
    </DrawerSx>
  );
};

NavBar.NavDrawer.propTypes = {
  active: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};
NavBar.NavDrawer.defaultProps = {
  isLoggedIn: null,
};

NavBar.Popover = function NavBarPopover(props) {
  const {
    isOpen, anchorEl = null, onCloseRequest, onLogout, permissions, countNewExp, countNewService, usingDefaultPassword,
  } = props;

  const onLogoutInternal = () => {
    onCloseRequest();
    onLogout();
  };

  const navigate = useNavigate();

  const onProfile = () => {
    navigate(PATHS.PROFILE);
  };

  const onAdmin = () => {
    navigate(PATHS.ADMIN);
  };

  const onServiceApproval = () => {
    navigate(PATHS.SERVICEAPPROVE);
  };

  const onProfExpApproval = () => {
    navigate(PATHS.PROFEXPAPPROVE);
  };

  const genServiceApproval = () => {
    if (!([AccountPermissions.INVALID, AccountPermissions.REGULAR_STUDENT, AccountPermissions.UNAUTH, AccountPermissions.ACTIVITIES_CHAIR, AccountPermissions.ACTIVITIES_REP].includes(permissions))) {
      return (
        <PopoverServiceApprovalButtonSx onClick={onServiceApproval}>
          <Badge color='secondary' badgeContent={countNewService}>
            {_getNavigationIcon('serviceapproval')}
          </Badge>
          Service Approval
        </PopoverServiceApprovalButtonSx>
      );
    }
  };

  const genProfExpApproval = () => {
    if (!([AccountPermissions.INVALID, AccountPermissions.REGULAR_STUDENT, AccountPermissions.UNAUTH, AccountPermissions.SERVICE_CHAIR, AccountPermissions.CLASS_SERVICE_REP].includes(permissions))) {
      return (
        <PopoverServiceApprovalButtonSx onClick={onProfExpApproval}>
          <Badge color='secondary' badgeContent={countNewExp}>
            {_getNavigationIcon('profexpapproval')}
          </Badge>
          Prof. Exp. Approval
        </PopoverServiceApprovalButtonSx>
      );
    }
  };

  const genAdminApproval = () => {
    if (!([AccountPermissions.INVALID, AccountPermissions.REGULAR_STUDENT, AccountPermissions.CLASS_SERVICE_REP, AccountPermissions.SERVICE_CHAIR, AccountPermissions.UNAUTH, AccountPermissions.ACTIVITIES_CHAIR, AccountPermissions.ACTIVITIES_REP].includes(permissions))) {
      return (
        <PopoverAdminButtonSx onClick={onAdmin}>
          {_getNavigationIcon('admin')}
          Admin
        </PopoverAdminButtonSx>
      );
    }
  };

  const boolish = true;

  return (
    <PopoverSx
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onCloseRequest}
    >
      <PopoverContainerSx>
        {/*Do an if account.permissions == certain level, then do this shit*/}
        <PopoverListSx>
          {genAdminApproval()}
          <PopoverProfileButtonSx onClick={onProfile}>
            <Badge color='secondary' badgeContent={usingDefaultPassword ? "!" : 0}>
              {_getNavigationIcon('profile')}
            </Badge>
            Profile
          </PopoverProfileButtonSx>
          {genServiceApproval()}
          {genProfExpApproval()}
          <PopoverLogoutButtonSx onClick={onLogoutInternal}>
            {_getNavigationIcon('logout')}
            Log out
          </PopoverLogoutButtonSx>
        </PopoverListSx>
      </PopoverContainerSx>
    </PopoverSx>
  );
};
NavBar.Popover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.element,
  onCloseRequest: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  usingDefaultPassword: PropTypes.bool.isRequired,
  countNewService: PropTypes.number.isRequired,
  countNewExp: PropTypes.number.isRequired,
};
NavBar.Popover.defaultProps = {
  anchorEl: null,
};
