import React, {
    useState,
    useEffect,
} from 'react';

// import {
//     Accordion,
//     AccordionActions,
//     AccordionDetails,
//     AccordionSummary,
//     Typography,
//     Button,
// } from "@mui/material";
import {
    ExpandMore,
} from "@mui/icons-material";

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary, {
    accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

const WHITE = '#FFFFFF';
const CLARKSON_GREEN = '#004F42';
const CLARKSON_YELLOW = '#F3D200';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${WHITE}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMore sx={{ color: CLARKSON_YELLOW }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: CLARKSON_GREEN,
    flexDirection: 'row-reverse',
    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
        transform: 'rotate(180deg)',
    },
    [`& .${accordionSummaryClasses.content}`]: {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: `1px solid ${WHITE}`,
}));

export const FAQElement = ({ id, q, a }) => {
    const [expanded, setExpanded] = React.useState('');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Accordion expanded={expanded === 'panel' + id} onChange={handleChange('panel' + id)}>
            <AccordionSummary aria-controls={'panel' + id + "-content"} id={'panel' + id + "-header"}>
                <span style={{ color: WHITE }}>{q}</span>
            </AccordionSummary>
            <AccordionDetails>
                <span>{a}</span>
            </AccordionDetails>
        </Accordion>
    );
};

