import ViewModel from "pattern/view_model";

export default class FaqViewModel extends ViewModel {
    /**
     * @param {String} id the id of the person (unique)
   * @param {String} question the name of the committee member.
   * @param {String} answer the path of the committee member image.
   */
    constructor(id, question, answer) {
        super();

        this.id = id;
        this.question = question;
        this.answer = answer;
    }
}

/**
 * Creates a new instance of the {@link FaqViewModel}.
 *
 * @param {Object} kwargs
 * @param {String} kwargs.id
 * @param {String} kwargs.question
 * @param {String} kwargs.answer
 *
 * @returns {FaqViewModel} the new instance.
 */
const create = ({
    id = '',
    question = '',
    answer = '',
} = {}) => new FaqViewModel(
    id,
    question,
    answer,
);

const convert = (model) => {
    if (typeof model.id !== 'string') {
        throw new Error('the model.id is not valid');
    }
    if (typeof model.question !== 'string') {
        throw new Error('the model.question is not valid');
    }
    if (typeof model.answer !== 'string') {
        throw new Error('the model.answer is not valid');
    }

    return new FaqViewModel(
        model.id,
        model.question,
        model.answer,
    );
};

export const faqViewModelFactory = {
    create,
    convert,
};