const guestModel = {
    guests: [
        {
            id: "guest1",
            name: "David Craig",
            image: "/images/members/blank_profile.png"
        },
        {
            id: "guest2",
            name: "Marcia Bradley",
            image: "/images/members/blank_profile.png"
        },
        {
            id: "guest3",
            name: "Marcy Wilcox",
            image: "/images/members/blank_profile.png"
        },
        {
            id: "guest4",
            name: "Hayley Shen",
            image: "/images/members/blank_profile.png"
        },
        {
            id: "guest5",
            name: "Chris Robinson",
            image: "/images/members/blank_profile.png"
        },
        {
            id: "guest6",
            name: "Jenny Townsend",
            image: "/images/members/blank_profile.png"
        },
    ]
}

export default guestModel;

// format: id, name, image
// same as speakers
