import React, {
    ReactElement,
    useEffect,
    useState,
} from 'react';
import PageTemplate from 'page_template';
import HeaderCard from 'components/header_card';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import * as PATHS from 'routing/paths';

import {
    Container,
} from '../resources_page/styles/resources_page.style';
import {
    Innerr,
    Heading1,
    Heading2,
    Heading3,
    Heading4,
    Heading5,
    Heading6,
    BreakLine,
    SummaryText,
    SummaryTextCenter,
    Image,
    ImgCaption,
} from './styles/reunion_pages.style';

import reunionPagesController from './controller/reunion_pages.controller';
import { FAQElement } from './components/faq_elem/faq_elem.style';
import { SnackbarSx } from 'about_page/styles/about_page.style';

const SNACKBAR_DURATION = 3000;

export default function ReunionFAQLandingPage() {
    const navigate = useNavigate();
    const location = useLocation();

    const [faq, setFAQ] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const getFAQ = async () => {
        const faqLst = await reunionPagesController.readFAQ();
        const dataLst = [];
        if (faqLst.length == 0) {
            dataLst.push(<SummaryTextCenter><i>No FAQ questions listed yet. Coming soon!</i></SummaryTextCenter >);
        } else {
            faqLst.forEach(s => {
                const newFAQ = (<FAQElement id={s.id} q={s.question} a={s.answer}></FAQElement>);
                dataLst.push(newFAQ);
            });
        }
        setFAQ(dataLst);
    }

    useEffect(() => {
        getFAQ();
    }, []);

    useEffect(() => {
        if (location.state && location.state.errorMessage) {
            setErrorMessage(location.state.errorMessage);
            // clears the state that was previously set.
            window.history.replaceState(null, '', location.pathname);
        }
    }, [location]);
    const onSnackbarClose = () => setErrorMessage('');

    return (
        <PageTemplate active="faq" reunion={true}>
            <HeaderCard bkgSrc="/images/headers/SpringAutumnSidewalk.jpg">
                <HeaderCard.Title>FAQ</HeaderCard.Title>
            </HeaderCard>
            <Container>
                <Innerr>
                    <Heading1>Clarkson University Honors Program Alumni Reunion Weekend</Heading1>
                    <BreakLine />
                    <Heading2>June 20-22, 2025</Heading2>
                    <p> </p>

                    {/* Adding FAQ from JSON */}
                    <div>
                        {faq}
                    </div>

                    <BreakLine />
                    <Image src='/images/reunion/Honors Group and Team Building 2010.JPG' />
                    <ImgCaption>Honors Group and Team Building - August 2010</ImgCaption>
                    <BreakLine />
                    <Image src='/images/reunion/Honors Grads C2009.JPG' />
                    <ImgCaption>Honors Graduating Class of 2009 - May 2009</ImgCaption>
                    <BreakLine />
                    <Heading2>More details to come!</Heading2>
                    <BreakLine />
                    <SummaryTextCenter><i>Issues with any photos? Feel free to send an email at webmaster@clarksonhonors.org with specific information and requests.</i></SummaryTextCenter>
                </Innerr>
            </Container>
            <SnackbarSx
                open={errorMessage.length > 0}
                autoHideDuration={SNACKBAR_DURATION}
                onClose={onSnackbarClose}
                message={errorMessage}
            />
        </PageTemplate>
    );
}