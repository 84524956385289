import { styled } from "@mui/system";
import {
    Box,
    Divider,
    Typography,
} from '@mui/material';

// const WHITE = '#FFFFFF';
// const CLARKSON_GREEN = '#004F42';
// const CLARKSON_YELLOW = '#F3D200';

export const Container = styled('div')`
    font-size: 20px;
    color: black;
`;

export const Inner = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 50px 5%;
    max-width: 1100px;
    margin: auto;
`;

export const ColumnsSx = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: inherit;
    justify-content: space-between;
`;

export const Innerr = styled(Typography)`
    display: flex;
    flex-direction: column;
    padding: 50px 5%;
    max-width: 1100px;
    margin: auto;
`;

export const Heading1 = styled(Typography)`
    font-size: 36px;
    font-weight: bold;
    color: #004e42;
    text-align: center;
`;

export const Heading2 = styled(Typography)`
    font-size: 52px;
    font-weight: bold;
    color: #ffcd00;
    text-align: center;
`;

export const Heading3 = styled(Typography)`
    font-size: 36px;
    font-weight: bold;
    color: #black;
    text-align: left;
`;
export const Heading4 = styled(Typography)`
    font-size: 30px;
    font-weight: bold;
    color: #black;
    text-align: left;
`;
export const Heading5 = styled(Typography)`
    font-size: 36px;
    font-weight: bold;
    color: #ffcd00;
    text-align: left;
    margin-left: 10px;
`;
export const Heading6 = styled(Typography)`
    font-size: 36px;
    font-weight: bold;
    color: #ffcd00;
    text-align: center;
`;
export const SummaryText = styled(Typography)`
    font-size: 20px;
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px 0 lightgray, 0 6px 20px 0 gray;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
`;
export const SummaryTextCenter = styled(Typography)`
    font-size: 20px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
`;
export const BreakLine = styled('hr')`
    width: 75%;
    color: #004e42;
`;
export const TableTC = styled('tc')`
    font-size: 20px;
    text-align: center;
    min-width: 33.3%;
`;
export const Image = styled('img')`
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-bottom-style: solid;
    border-bottom-width: 6px;
    border-bottom-color: #004F42;
    border-left-style: solid;
    border-left-width: 6px;
    border-left-color: #F3D200;
    box-shadow: 0px 4px 8px 0 lightgray, 0 6px 20px 0 gray;
`;
export const ImgCaption = styled(Typography)`
    font-size: 18px;
    text-align: center;
    margin-top: -5px;
    font-style: italic;
    font-weight: 300;
    margin-bottom: 10px;
`;