import * as PATHS from 'routing/paths';

const navBarModel = {
  items: [
    {
      id: 'home',
      label: 'Home',
      path: PATHS.HOME,
    },
    {
      id: 'about',
      label: 'About',
      path: PATHS.ABOUT,
    },
    {
      id: 'service',
      label: 'Service',
      path: PATHS.SERVICE,
    },
    {
      id: 'professionalexperiences',
      label: 'Pre-Professional',
      path: PATHS.PROFEXP,
    },
    {
      id: 'documents',
      label: 'Documents',
      path: PATHS.DOCUMENTS,
    },
    {
      id: 'resources',
      label: 'Resources',
      path: PATHS.RESOURCES,
    },
  ],
};

const reunionNavBarModel = {
  items: [
    {
      id: 'home',
      label: 'Home',
      path: PATHS.REUNION,
    },
    {
      id: 'schedule',
      label: 'Schedule Details',
      path: PATHS.REUNION + "/schedule",
    },
    {
      id: 'speakers',
      label: 'Speakers',
      path: PATHS.REUNION + "/speakers",
    },
    {
      id: 'guests',
      label: 'Special Guests',
      path: PATHS.REUNION + "/guests",
    },
    {
      id: 'faq',
      label: 'FAQ',
      path: PATHS.REUNION + "/faq",
    },
  ],
};

const navBarAllModels = {
  navBarModel,
  reunionNavBarModel
};

export default navBarAllModels;
