import React, {
    ReactElement,
    useEffect,
} from 'react';
import PageTemplate from 'page_template';
import HeaderCard from 'components/header_card';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import * as PATHS from 'routing/paths';

import {
    Container,
} from '../resources_page/styles/resources_page.style';
import {
    Innerr,
    Heading1,
    Heading2,
    Heading3,
    Heading4,
    Heading5,
    Heading6,
    BreakLine,
    SummaryText,
    SummaryTextCenter,
    TableTC,
    Image,
    ImgCaption,
} from './styles/reunion_pages.style';

export default function ReunionScheduleLandingPage() {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <PageTemplate active="schedule" reunion={true}>
            <HeaderCard bkgSrc="/images/headers/DroneFall.jpg">
                <HeaderCard.Title>Schedule Details</HeaderCard.Title>
            </HeaderCard>
            <Container>
                <Innerr>
                    <Heading1>Clarkson University Honors Program Alumni Reunion Weekend Schedule</Heading1>
                    <BreakLine />
                    <Heading2>June 20-22, 2025</Heading2>
                    <SummaryTextCenter>Scroll down to view the current schedule for the Honors Program 2025 Alumni Reunion!</SummaryTextCenter>
                    <BreakLine />

                    <SummaryText>
                        <Heading3 style={{ marginLeft: '10px', textDecorationLine: 'underline' }}>June 20</Heading3>
                        <ul style={{ marginTop: 0 }}>
                            <li><b>Evening | </b>All-In Family Dinner</li>
                            <ul>
                                <li>Open to any Alumni Family, Friend, Faculty, or Staff Member connected with to the program!</li>
                                <li>Dinner Menu:</li>
                                <ul>
                                    <li>Grilled BBQ Chicken</li>
                                    <li>Hotdogs & Cheeseburgers with all the Fixings</li>
                                    <li>Grilled Mixed Vegetables</li>
                                    <li>Tossed Salad</li>
                                    <li>Potato Salad</li>
                                    <li>Macaroni Salad</li>
                                    <li>Mixed Fruit</li>
                                    <li>David's Cookies (quarter cuts)</li>
                                    <li>Cash bar with complementary soda, lemonade, water</li>
                                </ul>
                            </ul>
                        </ul>
                    </SummaryText>

                    <Image src='/images/reunion/Honors Picnic_2009.jpg' />
                    <ImgCaption>Honors Picnic - August 2009</ImgCaption>
                    <BreakLine />

                    <SummaryText>
                        <Heading3 style={{ marginLeft: '10px', textDecorationLine: 'underline' }}>Saturday, June 21</Heading3>
                        <ul style={{ marginTop: 0 }}>
                            <li><b>9 AM | </b>CUHP Business Breakfast & Program Update</li>
                            <ul>
                                <li><i>Led by Dr. Kate Krueger</i></li>
                                <li><i>Price Hall, Honors Office</i></li>
                            </ul>
                            <li><b>11 AM | </b>Lab Tours</li>
                            <li><b>3 - 4:30 PM | </b>Honors Program Ice Cream Social</li>
                            <ul>
                                <li>Menu:</li>
                                <ul>
                                    <li>Your favorite Ben & Jerry's flavor</li>
                                    <li>Commemorative gift</li>
                                </ul>
                                <li><i>Student Center Patio by Fire Pit</i></li>
                            </ul>
                        </ul>
                    </SummaryText>

                    <Image src='/images/reunion/Honors Pint Night Fall 22.jpg' />
                    <ImgCaption>Honors Pint Night - November 2022</ImgCaption>
                    <BreakLine />

                    <SummaryText>
                        <Heading3 style={{ marginLeft: '10px', textDecorationLine: 'underline' }}>June 22</Heading3>
                        <ul style={{ marginTop: 0 }}>
                            <li><b>8 AM | </b>Good-Bye Coffee</li>
                            <ul>
                                <li><i>Price Hall, Honors Office</i></li>
                            </ul>
                        </ul>
                    </SummaryText>

                    <Image src='/images/reunion/HP200 Showcase_2009 C2012.jpg' />
                    <ImgCaption>HP 200 Showcase - Fall 2009</ImgCaption>
                    <BreakLine />
                    <Image src='/images/reunion/Sophomore Honors Class 2014 C2016_2.jpg' />
                    <ImgCaption>HP201 Class - March 2014</ImgCaption>
                    <BreakLine />
                    <Heading2>More details to come!</Heading2>
                    <BreakLine />
                    <SummaryTextCenter><i>Issues with any photos? Feel free to send an email at webmaster@clarksonhonors.org with specific information and requests.</i></SummaryTextCenter>
                </Innerr>
            </Container>
        </PageTemplate>
    );
}