import React, {
    ReactElement,
    useEffect,
    useState,
} from 'react';
import PageTemplate from 'page_template';
import HeaderCard from 'components/header_card';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import * as PATHS from 'routing/paths';

import {
    Container,
} from '../resources_page/styles/resources_page.style';
import {
    Innerr,
    Heading1,
    Heading2,
    Heading3,
    Heading4,
    Heading5,
    Heading6,
    BreakLine,
    SummaryText,
    SummaryTextCenter,
    Image,
    ImgCaption,
} from './styles/reunion_pages.style';

import reunionPagesController from './controller/reunion_pages.controller';
import PersonCard from './components/person_card';
import { MemberGroupSx } from 'about_page/styles/about_page.style';

import { SnackbarSx } from 'about_page/styles/about_page.style';

const SNACKBAR_DURATION = 3000;

export default function ReunionSpeakerLandingPage() {
    const navigate = useNavigate();
    const location = useLocation();

    const [speakers, setSpeakers] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const getSpeakers = async () => {
        const speakerLst = await reunionPagesController.readSpeakers();
        const dataList = [];
        if (speakerLst.length == 0) {
            dataList.push(<SummaryTextCenter><i>No speakers listed yet. Coming soon!</i></SummaryTextCenter >)
        } else {
            speakerLst.forEach(s => {
                const newPerson = (<PersonCard key={s.id}>
                    <PersonCard.Image src={s.image} />
                    <PersonCard.Name>
                        {s.name}
                    </PersonCard.Name>
                </PersonCard>);
                dataList.push(newPerson);
            });
        }
        setSpeakers(dataList);
    }

    useEffect(() => {
        getSpeakers();
    }, []);

    useEffect(() => {
        if (location.state && location.state.errorMessage) {
            setErrorMessage(location.state.errorMessage);
            // clears the state that was previously set.
            window.history.replaceState(null, '', location.pathname);
        }
    }, [location]);
    const onSnackbarClose = () => setErrorMessage('');

    return (
        <PageTemplate active="speakers" reunion={true}>
            <HeaderCard bkgSrc="/images/headers/SunnySummerDay.jpg">
                <HeaderCard.Title>Speakers</HeaderCard.Title>
            </HeaderCard>
            <Container>
                <Innerr>
                    <Heading1>Clarkson University Honors Program Alumni Reunion Weekend</Heading1>
                    <BreakLine />
                    <Heading2>June 20-22, 2025</Heading2>

                    {/* Speakers */}
                    <p> </p>
                    <MemberGroupSx>
                        {speakers}
                    </MemberGroupSx>

                    <BreakLine />
                    <Image src='/images/reunion/Honors Summer Group_2009.jpg' />
                    <ImgCaption>Honors Summer Group & Research - July 2009</ImgCaption>
                    <BreakLine />
                    <Image src='/images/reunion/Honors Brunch 2023 Spring.jpg' />
                    <ImgCaption>Honors Brunch for Graduating Class - May 2023</ImgCaption>
                    <BreakLine />
                    <Heading2>More details to come!</Heading2>
                    <BreakLine />
                    <SummaryTextCenter><i>Issues with any photos? Feel free to send an email at webmaster@clarksonhonors.org with specific information and requests.</i></SummaryTextCenter>
                </Innerr>
            </Container>
            <SnackbarSx
                open={errorMessage.length > 0}
                autoHideDuration={SNACKBAR_DURATION}
                onClose={onSnackbarClose}
                message={errorMessage}
            />
        </PageTemplate>
    );
}