import faqModel from "reunion_page/model/faq.model";
import guestModel from "reunion_page/model/guests.model";
import speakerModel from "reunion_page/model/speakers.model";

import GuestViewModel, {
    guestViewModelFactory
} from "reunion_page/view_model/guest.viewmodel";
import SpeakerViewModel, {
    speakerViewModelFactory
} from "reunion_page/view_model/speaker.viewmodel";
import FaqViewModel, {
    faqViewModelFactory
} from "reunion_page/view_model/faq.viewmodel";

const readGuests = async () => guestModel.guests.map((item) => guestViewModelFactory.convert(item));

const readSpeakers = async () => speakerModel.speakers.map((item) => speakerViewModelFactory.convert(item));

const readFAQ = async () => faqModel.faqs.map((item) => faqViewModelFactory.convert(item));

const reunionPagesController = {
    readGuests,
    readSpeakers,
    readFAQ,
};

export default reunionPagesController;
