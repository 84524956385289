import PropTypes from "prop-types";
import React, {
    ReactElement
} from 'react';
import {
    ContainerSx,
    ImageSx,
    NameSx,
} from 'about_page/member_card/styles/member_card.style';

/**
 * PersonCard - used to give information name/image for all people idntified
 * Intended for reunion pages
 */

export default function PersonCard({ children }) {
    return <ContainerSx>{children}</ContainerSx>
}

PersonCard.propTypes = {
    children: PropTypes.node.isRequired,
};

// Image
PersonCard.Image = function PersonCardImage({ src }) {
    // TODO: validate the image src
    const validatedSrc = src !== '' ? src : '/images/members/blank_profile.png';
    return <ImageSx src={validatedSrc} />;
};

PersonCard.Image.propTypes = {
    src: PropTypes.string.isRequired,
};

// Name
PersonCard.Name = function PersonCardName({ children }) {
    return <NameSx>{children}</NameSx>;
};

PersonCard.Name.propTypes = {
    children: PropTypes.string.isRequired,
};