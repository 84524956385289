import ViewModel from "pattern/view_model";

export default class GuestViewModel extends ViewModel {
    /**
     * @param {String} id the id of the person (unique)
   * @param {String} name the name of the committee member.
   * @param {String} image the path of the committee member image.
   */
    constructor(id, name, image) {
        super();

        this.id = id;
        this.name = name;
        this.image = image;
    }
}

/**
 * Creates a new instance of the {@link GuestViewModel}.
 *
 * @param {Object} kwargs
 * @param {String} kwargs.id
 * @param {String} kwargs.name
 * @param {String} kwargs.image
 *
 * @returns {GuestViewModel} the new instance.
 */
const create = ({
    id = '',
    name = '',
    image = '',
} = {}) => new GuestViewModel(
    id,
    name,
    image,
);

const convert = (model) => {
    if (typeof model.id !== 'string') {
        throw new Error('the model.id is not valid');
    }
    if (typeof model.name !== 'string') {
        throw new Error('the model.name is not valid');
    }
    if (typeof model.image !== 'string') {
        throw new Error('the model.image is not valid');
    }

    return new GuestViewModel(
        model.id,
        model.name,
        model.image,
    );
};

export const guestViewModelFactory = {
    create,
    convert,
};